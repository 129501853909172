@import "~scss/breakpoints";
@import "~scss/type";

.filters {
  margin: 1rem 0;
  width: 100%;

  .filter-label {
    @extend %type--citation;
    font-size: var(--size-h5);
    letter-spacing: 1px;
    padding: 1rem 0rem;
  }

  li {
    display: flex;
    min-height: 20px;
  }

  label {
    @extend %type--body-copy;
    font-size: var(--size-h5);
    line-height: 1;
  }

  ul {
    list-style: none;
  }

  @include breakpoint(md) {
    .filter-label {
      padding: 2rem 0;
    }

    li {
      min-height: 30px;
    }
  }
}

.filter-nav {
  background-color: var(--white);
  margin: 2.5rem auto 2.5rem;
  padding: 4rem 0 0;
  // position: sticky;
  // top: var(--navHeightSm);
  z-index: 3;

  > div:first-of-type {
    &:before {
      background-color: var(--gray);
      content: '';
      height: 1px;
      left: 2rem;
      margin: 0 auto;
      position: absolute;
      right: 2rem;
      top: 0;

      @include breakpoint(md) {
        left: var(--margin);
        right: var(--margin);
      }
    }
  }
}

.filters-label {
  @extend %type--citation;
  bottom: 100%;
  left: 2rem;
  right: 0;
  margin-bottom: 0.5rem;
  position: absolute;

  @include breakpoint(md) {
    left: var(--margin);
    right: var(--margin);
  }
}

.filters.grid {
  display: block;

  > div ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(md) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding-bottom: 1rem;
  }
}

.filter-apply {
  grid-column-end: 4;
  grid-column-start: 1;
  margin: 2rem 0 3rem;
  text-align: center;
}

.filter-nav-collapse {
  bottom: 0.5rem;
  margin-right: 5px;
  position: absolute;
  right: var(--margin);

  &:hover {
    cursor: pointer;
  }
}

.custom-checkbox {
  cursor: pointer;
  display: block;
  padding-left: 20px;
  position: relative;
  user-select: none;

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  &.disabled {
    opacity: 0.33;
    pointer-events: none;
  }

  .checkbox {
    border-radius: 0;
    border: 1px solid var(--black);
    display: block;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 12px;
  }

  &:hover input ~ .checkbox {
    background-color: var(--bg-gray);
  }

  input:checked ~ .checkbox {
    background-color: var(--black);
  }
}

.news-button-alignment {
  grid-column-start: 2;
  .center {
    text-align: center;
  }
}

.home-button-alignment {
  @include breakpoint(lg) {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
  }
}

.filter-date {
  margin-bottom: 2rem;
}


#date-rang {
  padding: 0 7px;
  width: 100%;

  @include breakpoint(md) {
    width: 85%;
  }
}

.rc-slider-handle {
  background-color: var(--med-red);
  border: 0;

  &:active {
    border-color: var(--med-red);
    box-shadow: 0 0 5px var(--med-red);
  }
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border: 2px solid var(--med-red);
  box-shadow: 0 0 5px var(--med-red);
}

.rc-slider-rail {
  background-color: var(--med-gray);
  height: 1px;

  &:before,
  &:after {
    background-color: var(--med-gray);
    content: '';
    height: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.rc-slider-track {
  background-color: var(--med-gray);
  border-radius: 0;
  height: 14px;
  top: 0;
}

.rc-slider-mark-text {
  transform: none !important;
  @extend %type--body-copy;

  &:first-of-type {
    left: -7px !important;
  }

  &:last-of-type {
    left: unset !important;
    right: -7px !important;
  }
}

.rc-slider-tooltip-arrow {
  display: none;
}

.rc-slider-tooltip-inner {
  background-color: transparent;
  box-shadow: none;
  color: var(--black);
  @extend %type--body-copy;
  font-size: var(--size-h5);
}

.ScrollToTop {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  left: 100%;
  margin-bottom: var(--pad-lg);
  position: sticky;
  top: 80%;
  width: var(--margin);
  z-index: 3;

  &:hover {
    cursor: pointer;
  }


  + article {
    margin-top: -50px;
  }

  @include breakpoint(md) {
    p {
      text-align: center;
    }

    + article {
      margin-top: -100px;
    }
  }

  @include breakpoint(xl) {
    font-size: 14px;

    width: 150px;
  }

  @include breakpoint(xxl) {
    padding-right: var(--pad);
  }
}
