@import "~scss/breakpoints";
@import "~scss/type";

.module {
  h3 {
    @extend %type--subheader-serif;
    max-width: 650px;
  }

  p {
    @extend %type--body-copy;
    font-weight: 400;
    max-width: 650px;
  }

  h3 + p {
    margin-top: 2rem;
  }

  a.button {
    margin-top: 0.5rem;
    &:first-of-type {
      margin-top: 1rem;
    }
  }

  a.inline-link {
    margin-top: 1rem;
  }

  .offset {
    background-color: var(--bg-light-gray);
  }

  @include breakpoint(md) {
    a.button {
      margin-top: 2.5rem;

      &:first-of-type {
        margin-top: 2.5rem;
      }
    }

    .center .module-content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .frame {
    margin-top: 1.5rem;
  }
}

.module-content {
  padding: 1.5rem 0;
  @include breakpoint(md) {
    .buttons {
      justify-content: flex-start;
    }
  }
}

.form-module {
  .form-copy { display: none; }

  @include breakpoint(md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 350px;
  }
}

.form-module-copy {
  p {
    font-size: var(--size-h4);
    padding-right: 1rem;
  }
}

.image-module {
  color: var(--white);
  position: relative;

  .bg-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    &:before {
      background-color: var(--black);
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.5;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.offset-image-module {
  @include breakpoint(md) {
    padding-bottom: 25% !important;
    position: relative;

    .offset {
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      width: 75%;
    }
  }

  @include breakpoint(xl) {
    padding-bottom: 15% !important;
  }

  .bg-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    padding-bottom: 50%;
    width: 100%;
  }
}

.grid-module {
  .flex-list {
    justify-content: space-around;
  }

  li {
    text-align: center;
    width: 100%;
  }

  figure {
    margin: 0 auto 1rem;
    width: 33%;
  }

  @include breakpoint(md) {
    .list-length--6 li,
    .list-length--5 li {
      width: 33%;
    }

    li {
      width: 50%;
    }
  }

  @include breakpoint(lg) {
    li {
      width: 25%;
    }
  }
}

.grid-module-img {
  max-width: 95px;
  margin: 0 auto;
}

.timeline {
  position: relative;
  list-style: none;
  margin: 0 auto;
  max-width: var(--max);

  @include breakpoint(md) {

    &:before {
      background-color: var(--white);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      width: 2px;
    }

    &:before {
      left: 50%;
    }

    .button {
      margin: 1rem auto;
    }

  }
}

.timeline-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem;
  position: relative;

  figure {
    padding: 0 1rem 1rem;
  }

  .timeline-block_content {
    padding: 0 1rem 1rem;
  }

  h4 {
    @extend %type--subheader-serif;
  }

  h5 {
    color: var(--white);
    font-family: var(--sans);
    font-size: var(--size-bodysmall);
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  @include breakpoint(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin: 2rem auto;
    max-width: 900px;

    &:before {
      border-bottom: 8px solid transparent;
      border-left: 12px solid var(--red);
      border-top: 8px solid transparent;
      content: '';
      left: 0;
      margin: auto;
      position: absolute;
      left: calc(50% + 5px);
      top: 0;
      transform: rotate(180deg);
      width: 0;
    }

    figure {
      // justify-self: center;
      // width: calc(50% - 2rem);

      img {
        max-width: 350px;
        max-height: 350px;
      }
    }

    .timeline-block_content {
      // justify-self: center;

      max-width: 400px;
    }

    // &:nth-child(odd) {
    //   .timeline-block_content {
    //     margin-left: auto;
    //   }
    // }

    &:nth-child(even) {

      .timeline-block_content {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1;
      }

      figure {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 1;
      }

      // .timeline-block_content {
      //   margin-right: auto;
      // }

      &:before {
        left: unset;
        right: calc(50% + 5px);
        transform: rotate(0);
      }
    }
  }
}
