@import "~scss/colors";
@import "~scss/type";
@import "~scss/breakpoints";

.link-list--big {
  margin: 1rem auto 0;
  max-width: var(--bigmax);
  padding-left: var(--margin);
  padding-right: var(--margin);

  @include breakpoint(md) {
    margin: 50px auto 0;
  }
}

.link-module {
  display: flex;
  flex-direction: column-reverse;
  margin: 2rem 0;

  &:first-of-type {
    margin-top: 0;
  }

  @include breakpoint(md) {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 2rem 0;

    &.center-mod {
      align-items: center;

      .link-module__image {
        max-width: 550px;
      }
    }

    &:nth-of-type(even):not(.statistic-statistic) {
      flex-direction: row-reverse;
    }
  }

}

.link-module__image {
  padding: 1rem 0;
  position: relative;
  overflow: hidden;

  @include breakpoint(md) {
    padding: 2rem 1rem;
    width: 55%;
  }

  &.double {
    .frame {
      margin: 1rem 0;
    }
  }
}

.link-module__content{
  padding: 1rem 0;

  h3 {
    @extend %type--subheader-serif;
  }

  p {
    @extend %type--body-copy;
    margin: 1rem 0;
    max-width: 450px;
  }

  @include breakpoint(md) {
    padding: 2rem 1rem;
    width: 40%;

    p {
      margin: 2rem 0 3rem;
    }
  }
}

.link-module__tags {
  margin-bottom: 2rem;

  h4 {
    font-weight: 700;
    font-size: var(--size-h4);
    margin-bottom: 1em;
  }
}

.grid-link {
  height: auto;
  margin: 1rem auto;
  max-width: var(--max);
  min-height: 350px;
  width: calc(100% - 2rem);

  h3 {
    font-weight: 600;
  }

  @include breakpoint(md) {
    width: calc(33% - 2rem);
  }
}

.tab-link {
  display: block;
  margin-top: 1rem;
  text-decoration: none;
  width: 100%;

  &.mobile-half {
    width: 50%;
  }

  p {
    font-size: var(--size-body);
    font-family: var(--serif);
    line-height: 1.5;
    color: var(--black);
  }

  &.has-img {
    display: flex;

    figure {
      width: 33%;
    }

    img {
      min-height: 100%;
      object-fit: cover;
    }

    div {
      padding: 1rem 1rem 1rem 2rem;
      width: 75%;
    }
  }

  .inline-link,
  .publish-date {
    font-size: var(--size-bodysmall);
    line-height: 18px;
  }

  .inline-link {
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-right: 10px;
    text-transform: uppercase;
  }

  > div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &:hover {
    .inline-link {
      color: var(--med-red);

      .arrow {
        left: 0.5rem;
        &:before { border-left-color: var(--med-red); }
      }
    }
  }

  @include breakpoint(md) {
    &.mobile-half {
      width: 100%;
    }
  }
}

.tab-link-news {
  position: relative;
  background-color: var(--bg-light-gray);
  padding: 3rem var(--pad) var(--pad);
}

.tab-link-actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  max-width: 400px;
  width: 100%;

  .inline-link {
    text-transform: uppercase;
  }
}

.tab-link-press {
  background-color: var(--white);
  border-bottom: 1px solid var(--bg-light-gray);
  position: relative;

  > p,
  > figure,
  > div {
    position: relative;
    z-index: 1;
  }

  &:before {
    @extend %hoverstate;
  }


  &:hover::before {
    height: 100%;
  }

  .tab-link-press_details {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1.5rem;

    span {
      max-width: 50%;
    }

    .publish-date {
      font-size: var(--size-bodysmall);
    }

    span:first-of-type {
      color: var(--black);
      @extend %type--link-inline;
      font-size: var(--size-bodysmall);
    }
  }
}

.back-link {
  .arrow {
    transform: rotate(180deg);
  }
}
