@font-face {
  font-family: 'Tiempos';
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/tiempos-headline-web-bold.woff2") format("woff2"),
       url("../fonts/tiempos-headline-web-bold.woff") format("woff"),
       url("../fonts/tiempos-headline-web-bold.eot") format("eot");
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos';
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/tiempos-headline-web-regular.woff2") format("woff2"),
       url("../fonts/tiempos-headline-web-regular.woff") format("woff"),
       url("../fonts/tiempos-headline-web-regular.eot") format("eot");
  font-display: swap;
}

@font-face {
  font-family: 'Tiempos';
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/tiempos-headline-web-medium.woff2") format("woff2"),
       url("../fonts/tiempos-headline-web-medium.woff") format("woff"),
       url("../fonts/tiempos-headline-web-medium.eot") format("eot");
  font-display: swap;
}
