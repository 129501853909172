@import "~scss/breakpoints";
@import "~scss/colors";
@import "~scss/type";

.page-header {
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  position: relative;

  h1 {
    @extend %type--subheader-serif;
    font-size: var(--size-h1-sm);
    margin: 1rem 0;
    max-width: 800px;
  }

  h2 {
    @extend %type--intro-text;
    max-width: 800px;
  }

  .header-details {
    @extend %type--intro-text;
    // font-size: var(--size-h3);
    font-weight: 400;
    color: var(--black);
  }

  &:after {
    background-color: var(--red);
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    width: 100px;
  }

  &.center {
    margin: 0 auto;
    text-align: center;

    h1 {
      margin: 0 auto;
    }

    h2 { margin: 3rem auto 0; }

    &:after {
      left: 50%;
      transform: translateX(-50%);
    }

    &.lift {
      &:after {
        display: none;
      }

      h2 {
        position: relative;

        &:after {
          background-color: var(--red);
          bottom: calc(100% + 1.5rem);
          content: '';
          height: 2px;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          width: 100px;
        }
      }
    }
  }

  &.lift {
    &:after {
      display: none;
    }

    h1 {
      padding-bottom: 2rem;
    }

    h2 {
      padding-top: 1rem;
      position: relative;

      &:after {
        background-color: var(--red);
        bottom: calc(100% + 1rem);
        content: '';
        left: 0;
        height: 2px;
        position: absolute;
        width: 100px;
      }
    }
  }

  &.center.bg--gray {
    padding: 2rem;
  }
}

.page-subheader {
  @extend %type--intro-text;
}

.hero--detail .page-header.lift {
  padding: 0;

  h2:after {
    background-color: var(--black);
  }
}

.hero--work .page-header.center {
  padding-left: 2rem;
  text-align: left;

  &:after {
    transform: none;
    left: 2rem;
  }

  @include breakpoint(md) {
    text-align: center;

    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
