@import "~scss/breakpoints";
@import "~scss/type";


.question-section {
  margin: 1.5rem 0;
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--bg-gray);

  h3 {
    @extend %type--cta-serif;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  h4,
  .article h4 {
    @extend %type--body-copy;
    font-weight: 600;
  }

  p {
    @extend %type--body-copy;
  }

  .page-grid {
    padding-top: var(--pad-top-lg);
  }
}

.question {
   h2 {
    @extend %type--subheader-serif;
  }
}

.question,
.question-section {
  display: block;
  margin-top: calc(-1 * var(--navHeight));
  padding-top: var(--navHeight);
}

.question-block .body-text {
  margin-top: 1rem !important;
}

.section-subheader a {
  @extend %type--body-copy;
}

ul.faq-nav-list {
  > li {
    list-style: none;
    margin-bottom: 2rem;

   > a {
      position: relative;

      &:before {
        border-bottom: 5px solid transparent;
        border-left: 8px solid var(--red);
        border-top: 5px solid transparent;
        content: '';
        left: -15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity 0.2s;
        opacity: 0;
        width: 0;
      }

      &.active:before {
        opacity: 1;
      }
    }
  }

  @include breakpoint(md) {
    max-height: calc(100vh - var(--navHeight));
    overflow: auto;
    padding-left: 15px;
  }
}

.faq-nav {
  a {
    color: var(--black);
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: var(--red);
    }

    &.active {
      color: var(--red);
    }
  }

  .section-header {
    border-bottom: 1px solid var(--bg-gray);
    margin: 0.5rem 0;
    display: block;
    padding: 0.5rem 0;

    &.active {
      color: var(--red);
    }
  }

  .section-subheader {
    list-style: none;
    margin: 0.5rem 0;
  }
}
