@import "~scss/breakpoints";
@import "~scss/type";

.split-list {
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;

  &:last-of-type:after {
    display: none;
  }

  &:after {
    background-color: var(--gray);
    bottom: 0;
    content: '';
    height: 1px;
    left: var(--margin);
    position: absolute;
    right: var(--margin);
  }

  a {
    color: var(--red);
    text-decoration: none;
  }

  &.grid--50 {
    grid-gap: 0;
  }

  @include breakpoint(md) {
    &.grid--50 {
      grid-gap: 100px;
    }
  }

  @include breakpoint(lg) {
    &.grid--50 {
      grid-gap: 150px;
    }
  }
}

.split-list-heading {
  padding: 2rem 0 1rem;

  h3 {
    @extend %type--subheader-serif;
  }

  p {
    font-family: var(--sans);
    line-height: 1.5;
    font-size: var(--size-h4);
    margin-top: 1rem;
  }

  @include breakpoint(md) {
    grid-gap: 100px;
    padding: 2rem 0;
  }
}

.split-list-list {
  padding: 1rem 0 2rem;

  @extend %type--body-copy;

  h4 {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;;

    li {
      padding-left: 1rem;
      position: relative;
    }

    li:before {
      content: "•";
      font-family: "times";
      left: 0;
      padding-right: 0.5rem;
      position: absolute;
      top: 0;
    }
  }

  @include breakpoint(md) {
    padding: 2rem 0;
  }
}

.is-ie .split-list.grid--50.grid .grid-block {
  @include breakpoint(md) {
    width: calc(50% - 50px);
  }

  @include breakpoint(lg) {
    width: calc(50% - 75px);
  }
}
