// $all-colors: (
//   'black',
//   'white',
//   'blue',
//   'med-blue',
//   'bg-blue',
//   'yellow',
//   'bg-yellow',
//   'green',
//   'bg-green',
//   'red',
//   'med-red',
//   'light-red',
//   'bg-red',
//   'gray',
//   'med-gray',
//   'bg-gray',
//   'bg-gray-light'
// );

$bg-colors: (
  'blue',
  'yellow',
  'green',
  'red',
  'gray',
  'light-gray'
);

@each $color in $bg-colors {
  %bg--#{$color} {
    background-color: var(--bg-#{$color});
  }

  .bg--#{$color} {
    background-color: var(--bg-#{$color});
  }
}

$type-colors: (
  'black',
  'white',
  'blue',
  'med-blue',
  'yellow',
  'green',
  'red',
  'med-red',
  'light-red',
  'gray',
  'med-gray',
  'navy'
);

@each $color in $type-colors {
  %color--#{$color} {
    color: var(--#{$color});
  }
}
