@import "~scss/breakpoints";
@import "~scss/type";

.signup-form {
  display: grid;
  grid-gap: 1rem;

  input[type="text"],
  input[type="email"] {
    @extend %type--body-copy;
    border: none;
    color: var(--black);
    padding: 0.5rem;
    width: 100%;
  }

  label {
    @extend %type--body-copy;
    color: var(--gray);
    font-style: italic;
    left: 0.5rem;
    pointer-events: none;
    position: absolute;
    top: 0.33rem;
    opacity: 1;

    &.hide {
      opacity: 0;
    }
  }

  .form-row {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 3;

    &.full {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
    .form-row {
      grid-column-end: 2;

      &:nth-of-type(2) {
        grid-column-start: 2;
      }
    }
  }
}

.form form {
  margin-top: 1rem;
}

#donate-form {
  background-color: var(--bg-blue);
  max-width: 800px;
}

#donate-form-block {
  max-width: 710px;
}

#donate-form-block,
#donate-form {
  transform: none;
  margin: 0 auto;

   input[type="number"] {
    width: 100%;
  }

  .button.blackBorder {
    background-color: var(--white);
    &.active {
      border-color: var(--red);
      color: var(--red);
    }
  }

  @include breakpoint(md) {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 1rem;

    .button {
      margin-right: 1rem;
    }

    input[type="number"] {
      width: calc(100% - 1rem);
    }
  }

  input[type="number"] {
    width: 100%;
    margin-bottom: 1rem;
  }

  @include breakpoint(md) {
    input[type="number"] {
      width: calc(100% - 1rem);
    }
  }
}

.donate-type .button {
  width: 100%;

  @include breakpoint(md) {
    min-width: none;
    width: calc(50% - 1rem);
  }
}

.donate-amount {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 0 !important;
}

// overrides
// ------------------------------------
.ctct-form-header {
  font-size: var(--size-h5) !important;
  font-weight: 600;
}

.ctct-inline-form label,
.ctct-inline-form input,
.ctct-form-text {
  font-size: var(--size-h5) !important;
}

.ctct-inline-form {
  label, input {
    @extend %type--body-copy;
    font-size: var(--size-h5) !important;
  }

  * {
    font-family: var(--sans) !important;
  }

  input {
    border-radius: 0 !important;
  }
}
.ctct-form-defaults {
  padding: 0 !important;
}

.ctct-form-button {
  @extend %type--button;
  border-radius: 0 !important;
  font-size: var(--size-bodysmall) !important;
  text-transform: uppercase !important;
}

.site-footer-form .ctct-inline-form {
  input {
    background-color: var(--black) !important;
    border: 1px solid var(--white) !important;
    color: var(--white) !important;
  }
}

.form-module .ctct-form-defaults {
  background-color: var(--bg-yellow) !important;

  .ctct-form-header,
  .ctct-form-text {
    display: none;
  }

  label,
  .ctct-gdpr-text,
  a {
    color: var(--black) !important;
  }

  a:hover {
    color: var(--red) !important;
  }

  .ctct-form-element {
    border: none !important;
  }

  .ctct-form-button {
    background-color: transparent !important;
    border: none !important;
    display: inline-block;
    color: var(--white) !important;
    position: relative;
    z-index: 1;

    &:before {
      background-color: var(--med-red);
      bottom: 0;
      content: '';
      height: 0;
      left: 0;
      position: absolute;
      right: 0;
      transition: all 0.3s;
      z-index: -1;
    }

    &:after {
      background-color: var(--red);
      bottom: 0;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -2;
    }

    &:hover {
      &:before {
        height: 100%;
      }
    }
  }

  #first_name_field_2,
  #last_name_field_2 {
    display: inline-block;
    width: calc(50% - 0.25rem);
  }
}

.ctct-form-field {
  .ctct-form-label {
    margin-bottom: 0 !important;
  }

  .ctct-form-required {
    margin-left: 10px;
  }
}

.ctct-form-button {
  display: inline-block !important;
  padding: 1.5rem 2rem !important;

  @include breakpoint(md) {
    width: auto !important;
  }
}

.ctct-gdpr-text {
  line-height: 1.26 !important;
}

#ctct_recaptcha_0 {
  opacity: 0;
  visibility: hidden;
}
