@import "~scss/breakpoints";

.section-header {
  font-family: var(--sans);
  font-size: var(--size-h5);
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;

  @include breakpoint(md) {
    text-align: left;
  }

  &.left {
    text-align: left;
  }

  &.large {
    margin: 2rem 1rem;
    text-align: center;
  }

  &.small {
    margin: 1rem 0;
  }

  &.underline {
    border-bottom: 1px solid var(--med-gray);
    padding-bottom: 1.5rem;
  }

  &.large.underline {
    max-width: var(--max);
    margin: 2rem auto;
  }
}
