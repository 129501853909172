@import "~scss/colors";
@import "~scss/type";
@import "~scss/breakpoints";

.facts-block {
  @extend %bg--blue;
  margin: 1rem 0 0.5rem;

  .fact p {
    max-width: 210px;
  }

  @include breakpoint(md) {
    margin: 2rem 0 1rem;
  }
}

.red .facts-block {
  @extend %bg--red;
}

.fact {
  font-family: var(--sans);
  margin-bottom: 2rem;
  padding: 1rem;
  text-align: center;

  h3 {
    @extend %type--fact;
    font-weight: 800;
    margin: 0;
  }

  h4 {
    @extend %type--h3;
    font-size: var(--size-h4);
    font-weight: 800;
    margin: 0 0 0.8rem;
    text-transform: uppercase;
  }

  p {
    @extend %type--body;
    margin: 0 auto;
  }

  &:after {
    display: none;
  }

  @include breakpoint(lg) {
    position: relative;

    &:after {
      background-color: var(--med-gray);
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      right: -20px;
      top: 0;
      width: 1px;
    }

    &:nth-of-type(4n):after {
      display: none;
    }
  }
}
