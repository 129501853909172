@import "~scss/type";
@import "~scss/colors";
@import "~scss/breakpoints";

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
  text-align: center;

  .button {
    min-width: 165px;
  }

  @include breakpoint(md) {
    align-items: center;
    flex-direction: row;

    .button {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.button {
  @extend %type--button;
  appearance: none;
  display: block;
  margin: 5px 0;
  min-width: 200px;
  outline: none;
  padding: 1.5rem 2rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  &:focus,
  &:focus-visible {
    outline: 1px solid black;
  }

  @include breakpoint(md) {
    display: inline-block;
    margin: 0 5px 0 0;
    width: auto;

    &:hover {
      cursor: pointer;
    }
  }
}

.defaultRed {
  border: 0;
  color: var(--white);
  position: relative;
  z-index: 1;

  &:before {
    background-color: var(--med-red);
    bottom: 0;
    content: '';
    height: 0;
    left: 0;
    position: absolute;
    right: 0;
    transition: all 0.3s;
    z-index: -1;
  }

  &:after {
    background-color: var(--red);
    bottom: 0;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -2;
  }

  &:hover::before {
    height: 100%;
  }
}

.whiteBorder {
  background-color: var(--white);
  border: 0;
  color: var(--black);
  position: relative;
  transition: color 0.3s;

  &.active {
    background-color: var(--black);
    color: var(--white);
  }

  &:hover {
    color: var(--red);
  }

  &:not(.active) {
    @extend %animateBorder;
  }
}


.nav-button .blackBorder {
  background-color: transparent;
  border: 1px solid var(--black);
  color: var(--black);
  position: relative;
  transition: color 0.3s;

  &.active {
    background-color: var(--black);
    color: var(--white);
  }

  &:not(.active) {
    @extend %animateBorder;
  }
}

.pagination .blackBorder {
  border: 1px solid var(--black);
  &:not(.active):hover {
    background-color: var(--bg-gray);
    color: var(--black);

    &:before, &:after { display: none; }
  }
}

.blackBorder {
  background-color: transparent;
  border: 1px solid var(--black);
  color: var(--black);
  position: relative;
  transition: color 0.3s;

  &:not(.active) {
    @extend %animateBorder;
  }

  &.active {
    background-color: var(--black);
    color: var(--white);
  }
}

// .greenBorder {
//   border-color: var(--green);
//   color: var(--green);
// }

// .whiteBorder {
//   background-color: transparent;
//   border: 1px solid var(--white);
//   color: var(--white);

//   &:not(.active) {
//     @extend %animateBorder;
//   }

//   // &:hover {
//   //   color: var(--med-red);
//   // }
//   // &:hover::before {
//   //   border-top-color: var(--med-red);
//   //   border-right-color: var(--med-red);
//   // }
//   // &:hover::after {
//   //   border-bottom-color: var(--med-red);
//   //   border-left-color: var(--med-red);
//   // }
// }

.button.white {
  background-color: transparent;
  border: 0;
  color: var(--black);
}

.button-small {
  @extend %type--button;
  display: block;
  font-family: var(--sans);
  margin: 0 5px;
  padding: 0.75rem 2rem;
  text-align: center;
  text-decoration: none;

  @include breakpoint(md) {
    display: inline-block;
  }
}

.button-xs {
  @extend %type--button;
  display: inline-block;
  font-family: var(--sans);
  margin: 0;
  min-width: 45px;
  padding: 0;
  text-decoration: none;
  transition: all 0.3s;

  a {
    display: block;
    padding: 0.75rem;
  }

  + .button-xs {
    border-left: 0;
  }
}

.button-lg {
  display: block;

  @include breakpoint(md) {
    max-width: 350px;
  }
}

.button-color {
  color: var(--black);
  margin: 0 10px 10px 0;
  padding: 0.65rem 0.75rem;
}

.button-icon {
  align-items: center;
  display: flex;
  justify-content: center;

  &:hover .play-icon {
    border-color: var(--red);
    &:after {
      border-left-color: var(--red);
    }
  }

  @include breakpoint(md) {
    display: flex;
    text-align: center;
  }
}

.play-icon {
  border-radius: 50%;
  border: 1px solid var(--black);
  display: block;
  height: 20px;
  margin: 0 5px;
  position: relative;
  transition: border 0.3s;
  width: 20px;

  &:after {
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    border-left: 8px solid var(--black);
    bottom: 4px;
    content: '';
    left: calc(50% - 3px);
    margin: auto;
    position: absolute;
    transition: border 0.3s;
    width: 0;
  }
}

.icon-up {
  background-color: transparent;
  border-bottom: 2px solid var(--black);
  border-left: 0;
  border-right: 2px solid var(--black);
  border-top: 0;
  height: 15px;
  min-width: 0;
  outline: none;
  padding: 0;
  transform: rotate(-135deg);
  transition: transform 0.3s;
  width: 15px;
}

%animateBorder {
  position: relative;

  &:before,
  &:after {
    border: 1px solid transparent;
    content: '';
    height: 0;
    left: -1px;
    margin: 0 auto;
    pointer-events: none;
    position: absolute;
    right: -1px;
    width: 0;
  }

  &:before {
    bottom: -1px;
    height: 0;
  }

  &:after {
    height: 1px;
    top: -1px;
  }

  &:hover {
    color: var(--red);
  }

  &:hover::before {
    border-bottom-color: var(--red);
    border-left-color: var(--red);
    border-right-color: var(--red);
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    transition:
      width 0.15s ease-out,
      height 0.15s ease-out 0.15s;
  }

  &:hover::after {
    border-top-color: var(--red);
    width: calc(100% + 2px);
    transition:
      border-color 0.15s ease-out 0.3s;
  }
}

.donate-type {
  .button:first-of-type {
    margin-bottom: 10px;

    @include breakpoint(md) {
      margin-bottom: 0;
    }
  }
}

// %animateBorderAround {
//   position: relative;

//   &:before,
//   &:after {
//     border: 1px solid transparent;
//     content: '';
//     height: 0;
//     position: absolute;
//     width: 0;
//   }

//   &:before {
//     left: -1px;
//     top: -1px;
//   }

//   &:after {
//     bottom: -1px;
//     right: -1px;
//   }

//    &:hover {
//     color: var(--red);
//   }

//   &:hover::before,
//   &:hover::after {
//     width: calc(100% + 2px);
//     height: calc(100% + 2px);
//   }

//   &:hover::before {
//     border-top-color: var(--red);
//     border-right-color: var(--red);
//     transition:
//       width 0.1s ease-out,
//       height 0.1s ease-out 0.1s;
//   }

//   &:hover::after {
//     border-bottom-color: var(--red);
//     border-left-color: var(--red);
//     transition:
//       border-color 0s ease-out 0.2s,
//       width 0.1s ease-out 0.2s,
//       height 0.1s ease-out 0.3s;
//   }
// }
