@import "~scss/type";
@import "~scss/colors";

.resource-card {
  border: 1px solid var(--gray);
}

.resource-card-link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 400px;
}

.resource-tag {
  @extend %type--label;
  display: inline-block;
  letter-spacing: 0.05rem;
  line-height: 25px;
  min-height: 25px;
  padding: 0 1.5rem;
}

.resource-body {
  h2 {
    margin: 0 5px 2rem;
    max-width: 900px;
  }
}

.download-button {
  p {
    @extend %type--body-copy;
    font-family: var(--serif);
    margin-bottom: 0.5rem;
    padding-right: 0.5rem;
  }
}

ul.reset.filter-nav {
  border-bottom: 1px solid var(--gray);
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.resource-authors {
  margin-bottom: 1rem;
}
