:root {
  --black: #041E26;
  --white: #fff;
  --bg-white: #fff;

  --navy: #5E7880;

  --blue: #83BAC3;
  --med-blue: #9CD4D5;
  --bg-blue: #E6F4F5;

  --yellow: #BAB037;
  --med-yellow: #EAE16A;
  --bg-yellow: #FAF8DA;

  --green: #466C53;
  --bg-green: #E2F8E9;

  --red: #C52F2C;
  --med-red: #D85D58;
  --light-red: #EC8A86;
  --bg-red: #FAE2E1;

  --gray: #A09E93;
  --med-gray: #B7B5AA;

  --bg-gray: #E9E9E6;
  --bg-light-gray: #F7F7F6;

  --navHeight: 110px;
  --navHeightSm: 80px;

  --footerHeight: 550px;

  --max: 1180px;

  --bigmax: 1600px;

  --sans: effra, sans-serif;
  --serif: 'Tiempos', serif;

  --pad: 1rem;
  --pad-lg: 1.5rem;

  --pad-top-lg: 3.5rem;

  --size-h1: 52px;
  // --size-h1: 2.125em;
  --line-h1: 1;

  --size-h1-sm: 32px;

  --size-h2: 24px;
  // --size-h2: 1.75em;
  --line-h2: 1.22;

  --size-h3: 20px;
  // --size-h3: 1.375em;
  --line-h3: 1.33;

  --size-h4: 16px;
  // --size-h4: 1.25em;
  --line-h4: 1;

  --size-h5: 14px;
  // --size-h5: 0.875em;
  --line-h5: 1;

  --size-bodyfeatured: 16px;
  // --size-bodyfeatured: 1.25em;
  --line-bodyfeatured: 1.3;

  --size-body: 14px;
  // --size-body: 1.125em;
  --line-body: 1.6;

  --size-bodysmall: 12px;
  // --size-bodysmall: 1em;

  --size-callout: 60px;
  // --size-callout: 3.75em;
  --line-callout: 1.13;

  --size-cta: 20px;
  // --size-cta: 0.875em;
  --line-cta: 1;

  --size-iconlabel: 30px;
  // --size-iconlabel: 1.875em;
  --line-iconlabel: 1.36;

  --size-fact: 60px;
  // --size-fact: 6.25em;
  --line-fact: 1em;

  --size-quick-fact: 16px;
  // --size-quick-fact: 1em;

  --margin: 2rem;


  @include breakpoint(md) {
    --max: 1400px;

    --line-body: 1.7;

    --size-h1: 72px;
    --size-h1-sm: 48px;
    --size-h2: 26px;
    --size-h3: 24px;
    --size-h4: 20px;
    --size-h5: 14px;

    --size-body: 16px;

    --size-fact: 100px;

    --pad: 2rem;
    --pad-lg: 3rem;

    --pad-top-lg: 7.5rem;

    --navHeight: 115px;
    --navHeightSm: 80px;

    --footerHeight: 450px;
    // --size-bodysmall: 12px;
  }

  @include breakpoint(lg) {
    // --max: 1400px;

    // --size-h1: 72px;
    --size-h2: 32px;
    --size-h3: 28px;
    // --size-h4: 20px;
    --size-h5: 16px;

    --size-body: 18px;

    --margin: 50px;

    // --size-fact: 100px;

    // --pad: 2rem;
    // --pad-lg: 3rem;

    // --navHeight: 110px;
    // --navHeightSm: 80px;

    // --footerHeight: 450px;
    // --size-bodysmall: 12px;
  }

  @include breakpoint(xl) {
    --margin: 125px;
  }

  @include breakpoint(xxl) {
   --max: 1600px;
   --bigmax: 1800px;

    // --size-h1: 72px;
    --size-h2: 36px;
    --size-h3: 30px;
    --size-h4: 24px;
    --size-h5: 18px;

    --size-body: 20px;

    // --size-fact: 100px;

    // --pad: 2rem;
    // --pad-lg: 3rem;

    // --navHeight: 110px;
    // --navHeightSm: 80px;

    // --footerHeight: 450px;
    // --size-bodysmall: 12px;
  }
}
