@import "~scss/breakpoints";
@import "~scss/type";


.body-text,
.article {
  max-width: var(--max);

  h2 {
    @extend %type--intro-text;
    max-width: 900px;
  }

  .body-text {
    h2 {
      font-size: var(--size-h1-sm);
      font-family: var(--sans);
      font-weight: 700;
    }
  }

  h3,h4,h5 {
    margin-bottom: 1rem;
  }

  h3 {
    @extend %type--subheader-serif;
  }

  blockquote {
    @extend %type--subheader-serif;
    font-size: var(--size-h2);
    margin: var(--pad-lg) 0;

    + h5 {
      @extend %type--citation;
      margin-top: calc(1.5rem + (-1 * var(--pad-lg)));
      margin-bottom: var(--pad-lg);
    }
  }

  h4 {
    @extend %type--cta-serif;
  }

  h5 {
    font-size: var(--size-h5);
    font-weight: 700;
  }

  h6 {
    font-family: var(--sans);
    font-size: var(--size-h5);
    font-weight: 800;
    text-transform: uppercase;
  }

  a:not(.button) {
    @extend %link--red;
  }

  ul {
    list-style: none;

    li {
      padding-left: 1rem;
      position: relative;
    }

    li:before {
      content: "•";
      font-family: "times";
      padding-right: 0.5rem;
      position: absolute;
      top: 0;
      left: 0;

    }
  }

  ol {
    padding-left: 1rem;
  }

  ol li {
    padding-left: 0.5rem;
  }

  strong {
    font-weight: 800;
  }

  em {
    font-style: italic;
  }

  .center {
    text-align: center;
  }
}

p,
.body-text {
  a {
    @extend %link--red;
  }
}

p {
  @extend %type--body-copy;

  strong {
    font-weight: 800;
  }

  em {
    font-style: italic;
  }
}

.statistic {
  p {
    max-width: 250px;
    padding-top: 1rem;
  }

  &:last-child:after {
    display: none;
  }

  @include breakpoint(md) {
    p { max-width: 380px; }
  }
}

.bodyCopy-bodyCopy,
.bodyBlock-bodyBlock,
.bodyCopy-bodyBlock {
  @include breakpoint(md) {
    .article {
      width: 50%;
    }
  }

  p {
    max-width: 550px;
  }
}

.image-image {
  align-items: flex-start;

  @include breakpoint(md) {
    .image {
      width: 50%;
    }
  }
}

.statistic-statistic {
  // flex-direction: row;
  width: 100%;

  @include breakpoint(md) {
    display: grid !important;
    grid-template-columns: 50% 50%;

    .statistic {
      &:last-child::after {
        display: none;
      }
    }
  }

}

.bodyCopy .bodyCopy.article {
  width: 100%;
}

.bodyBlock .bodyBlock.article {
  width: 100%;
  p {
    max-width: 800px;
  }
}
.image .image.article {
  width: 100%;
}
.statistic .statistic.article {
  width: 100%;
}

.bodyCopy-image,
.bodyBlock-image {
  max-width: var(--bigmax);
  margin: 0 auto;
  align-items: flex-start;

  @include breakpoint(md) {
    .article {
      margin-top: 2rem;
    }

    .body-text {
      margin-top: 10px;
    }

    .image {
      margin: 50px 0;
      width: 50%;
    }
  }
}

.image-statistic {
  align-items: center;

  .statistic {
    text-align: left;

    &:after {
      display: none;
    }
  }

  h3 {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    position: relative;

    &:after {
      background-color: var(--red);
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: 130px
    }
  }

  p {
    margin: 0;
  }
}

.bodyCopy-statistic,
.bodyBlock-statistic {
  .statistic {
    text-align: left;

    &:after {
      display: none;
    }
  }
  @include breakpoint(md) {
    .article {
      width: 55%;
    }
  }
}


.bodyBlock.article.link-module__content > .body-text {
  margin-top: 0;
}

.author {
  margin-bottom: 2rem;
  padding: var(--pad) 0;
  position: relative;

  p {
    @extend %type--body-copy;
  }

  &:after {
    background-color: var(--bg-gray);
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    width: 3rem;
  }
}

.author-img {
  align-items: center;
  display: flex;

  p {
    margin-left: 1rem;
    span {
      display: block;
    }
  }
}

.plain-text {
  h2 {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    position: relative;

    &:after {
      background-color: var(--bg-gray);
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      width: 100px;
    }
  }

  p {
    font-size: var(--size-h5);
    font-family: var(--sans);
    font-weight: 400;
    line-height: var(--line-body);
  }
}

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
