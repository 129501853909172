@import "~scss/breakpoints";
@import "~scss/type";

.staff-member {}

.board-member {
  justify-content: flex-start;

  display: block;
  position: relative;

  > div {
    position: relative;
    z-index: 2;
  }

  &:after {
    @extend %hoverstate;
  }

  &:hover {
    &:after {
      height: 100%;
    }
  }
}

.person {
  color: var(--black);
  height: 100%;
  text-decoration: none;

  h3 {
    @extend %type--cta-serif;
    font-size: var(--size-h2);
    font-weight: 700;
  }

  h4 {
    @extend %type--body-copy;
    font-size: var(--size-h4);
    margin-top: 0.5rem;

    + h4 {
      margin-top: 0;
    }
  }

  > div:not(.frame) {
    padding: 15px 0 40px;
  }

  @include breakpoint(md) {
    > div,
    > div:not(.frame) {
      padding: 1.5rem;
    }

    h3 {
      font-size: var(--size-h4);
    }
  }
}
