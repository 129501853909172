@import "~scss/breakpoints";
@import "~scss/type";

.site-footer {
  @extend %type--body-copy;
  font-size: var(--size-h5);
  background-color: var(--black);
  color: var(--white);
  font-weight: 400;
  // min-height: var(--footerHeight);

  a {
    color: var(--white);
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: var(--med-red);
      svg * {
        fill: var(--med-red);
      }
    }
  }

  .grid--33 {
    grid-gap: 1rem;
  }

  svg * {
    fill: var(--white);
    transition: fill 0.3s;
  }

  .svg-link svg * { fill: var(--white); }

  .site-footer-heading {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .center {
    text-align: center;
  }
}

.site-footer-logo {
  display: block;
  width: 100%;

  svg {
    max-width: 200px;
  }
}

.site-footer-section {
  margin-bottom: 1rem;
  @include breakpoint(md) {
    margin-bottom: 0;
  }
}

.site-footer-details {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  order: 2;
  width: 100%;

  div:first-of-type {
    padding-right: 1rem;
    margin-bottom: 1rem;
  }

  @include breakpoint(md) {
    order: initial;
  }
}

.site-footer-partners {
  margin-top: 1rem;
  width: 100%;

  a {
    display: inline-block;
    max-width: 100px;
    margin-right: 3rem;
  }
}

.site-footer-links {
  order: 0;

  a {
    font-weight: 800;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  >ul li {
    margin: 0 0 1rem;
    width: 50%;
  }

  @include breakpoint(md) {
    order: initial;
  }
}

.site-footer-form {
  order: 1;

  p:first-of-type {
    @extend .site-footer-heading;
  }

  @include breakpoint(md) {
    order: initial;
  }
}

.site-footer-copyright {
  color: var(--gray);
  font-size: var(--size-bodysmall);

  a {
    color: var(--gray);
    &:hover {
      color: var(--white);
    }
  }

  @include breakpoint(md) {
    text-align: center;
    margin-top: 3rem;
  }
}

.is-ie .site-footer {
  .grid {
    display: flex;
  }

  @include breakpoint(lg) {
    .site-footer-section,
    .site-footer-details {
      width: 33%;
    }

    .site-footer-partners {
      width: 100%;
    }
  }
}
