@import "~scss/breakpoints";

.modal {
  background-color: rgba(0,0,0, 0.7);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 15;

  .close {
    height: 20px;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    width: 20px;

    &:hover {
      cursor: pointer;
    }

    .close-icon {
      display: block;
      height: 100%;
      position: relative;
      width: 100%;
      transition: transform 0.3s;

      &:hover {
        background-color: transparent;
        transform: rotate(180deg);
      }

      &:before {
        background-color: var(--white);
        bottom: 0;
        content: '';
        position: absolute;
        top: 0;
        transform: rotate(45deg);
        width: 2px;
      }

      &:after {
        background-color: var(--white);
        bottom: 0;
        content: '';
        position: absolute;
        top: 0;
        transform: rotate(-45deg);
        width: 2px;
      }
    }
  }

  video {
    left: 50%;
    max-width: 85vw;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
  }

  @include breakpoint(md) {
    .close {
      height: 50px;
      width: 50px;
    }
  }
}

body.modal-open {
  height: 100vh;
  overflow: hidden;
}
