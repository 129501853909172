$typeStyles: (
  'h1',
  'h1-sm',
  'h2',
  'h3',
  'h4',
  'h5',
  'bodyfeatured',
  'body',
  'bodysmall',
  'cta',
  'iconlabel',
  'fact',
  'quick-fact',
);

@each $type in $typeStyles {
  %type--#{$type} {
    font-size: var(--size-#{$type});
    line-height: var(--line-#{$type});
  }
}

%type--header-serif {
  font-size: var(--size-h1-sm);
  font-family: var(--serif);
  font-weight: 800;
  line-height: var(--line-h3);
}

%type--header-sans {
  font-size: var(--size-h1);
  font-family: var(--sans);
  font-weight: 700;
}

%type--subheader-serif {
  font-size: var(--size-h2);
  font-family: var(--serif);
  font-weight: 700;
  letter-spacing: 0.02rem;
  line-height: 1.3;
}

%type--subheader-sans {
  font-size: var(--size-h2);
  font-family: var(--sans);
  font-weight: 700;
}

%type--callout {
  font-size: var(--size-h2);
  font-family: var(--sans);
  font-weight: 300;
  line-height: 1.5;
}

%type--intro-text {
  font-size: var(--size-h3);
  font-family: var(--sans);
  font-weight: 300;
  line-height: 1.5;
}

%type--cta-serif {
  font-size: var(--size-h4);
  font-family: var(--serif);
  font-weight: 500;
  line-height: 1.3;
}

%type--button {
  font-family: var(--sans);
  font-size: var(--size-bodysmall);
  font-weight: 500;
  letter-spacing: 1px;
}

%type--body-copy {
  font-size: var(--size-body);
  font-family: var(--sans);
  font-weight: 400;
  line-height: var(--line-body);
}

%type--date-time {
  font-family: var(--sans);
  font-size: var(--size-body);
  font-weight: 400;
  color: var(--gray);
}

%type--link-inline {
  font-family: var(--sans);
  font-size: var(--size-body);
  font-weight: 500;
  text-transform: uppercase;
}

%type--citation {
  font-family: var(--sans);
  font-size: var(--size-body);
  font-weight: 900;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

%type--label {
  background-color: var(--black);
  color: var(--white);
  font-family: var(--sans);
  font-size: var(--size-bodysmall);
  font-weight: 400;
  padding: 5px 1rem;
  text-transform: uppercase;
}

%link--red {
  color: var(--red);
  text-decoration: none;
  transition: color 0.3s;
  &:hover {
    color: var(--black);
  }
}

%hoverstate {
  background-color: var(--bg-yellow);
  bottom: 1px;
  content: '';
  height: 0;
  left: 0rem;
  position: absolute;
  right: 0;
  transition: all 0.3s;
  z-index: 1;
}
