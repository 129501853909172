@import "~scss/type";

.page-title {
  @extend %type--header-serif;
  margin: 2rem 1rem;
  position: relative;
  text-align: center;

  &:after {
    background-color: var(--red);
    bottom: -2rem;
    content: '';
    height: 2px;
    left: calc(50% - 47px);
    margin: auto;
    position: absolute;
    width: 95px;
  }
}

.page-title--small {
  @extend %type--subheader-serif;
}
