@import "~scss/type";
@import "~scss/breakpoints";

.card {
  color: var(--black);
  display: block;
  height: 100%;
  position: relative;
  text-decoration: none;

  a {
    color: var(--black);
    text-decoration: none;
    width: 100%;
  }

  h3 {
    @extend %type--subheader-serif;
    font-size: var(--size-h4);
    font-weight: 500;
    margin-bottom: 0.25rem;
  }

  .body-text, .publish-date {
    font-size: var(--size-h5);
    line-height: var(--line-h3);
  }

  img,a,p,h3,span,svg {
    position: relative;
    z-index: 1;
  }

  .svg-wrap {
    background-color: var(--bg-gray);
  }

  &:before {
    @extend %hoverstate;
  }

  &:hover {
    cursor: pointer;

    .inline-link {
      color: var(--med-red);

      .arrow {
        left: 0.5rem;
        &:before { border-left-color: var(--med-red); }
      }
    }
  }

  .resource-svg {
    align-items: center;
    background-color: var(--bg-gray);
    display: flex;
    justify-content: center;

    svg {
      width: 100%;
    }
  }

  &.resource-card .resource-svg {
    padding-bottom: 0;
  }

  @include breakpoint(md) {
    &.resource-card .resource-image,
    &.resource-card .resource-image-pad {
      width: calc(100% - 3rem);
    }

    .svg-wrap {
      background-color: transparent;
    }

    &:hover::before { height: calc(100% - 1px); }
  }
}

.resource-image-pad {
  background-color: var(--bg-gray);
  padding-bottom: 100%;

  @include breakpoint(md) {
    padding-bottom: calc(100% - 3rem);
  }

  img {
    box-shadow: 0.3em 0.3em 1em rgba(0,0,0,0.3);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    // width: calc(100% - 6rem);

    width: auto;
    object-fit: contain !important;
    max-height: calc(100% - 6rem);
    max-width: calc(100% - 6rem);
  }
}

.card-tag {
  @extend %type--label;
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  letter-spacing: 0.05rem;
  min-height: 25px;
  padding: 0.25rem 1.5rem;
  position: absolute;
  top: 0;
  z-index: 2;

  &.red {
    background-color: var(--red);
  }
}

.card-with-img {
  figure {
    height: 45%;
    position: relative;

    &:before {
      background-color: inherit;
      bottom: 0;
      content: '';
      left: 0;
      right: 0;
      position: absolute;
    }
  }

  svg,img {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }

  svg {
    background-color: var(--bg-gray);
  }

  .card-content {
    height: 55%;
    width: 100%;
  }

  &.img--back {
    figure {
      width: 100%;

      &:before {
        height: 0;
      }
    }
  }

  &.img--front {
    figure {
      width: 100%;

      &:before {
        height: 0;
      }
    }

    .card-content {
      width: 100%;
    }
  }

  @include breakpoint(md) {
    &.img--back {
      figure {
        svg, img {width: calc(100% - 3rem);}

        &:before {
          height: 50%;
        }
      }
    }

    &.img--front .card-content {
      width: calc(100% - 3rem);
    }
  }

  @include breakpoint(lg) {
    display: flex;
    flex-direction: column;

    figure {
      height: 200px;
    }
    .card-content {
      height: calc(100% - 200px);
    }
  }

  @include breakpoint(xl) {
    figure {
      height: 250px;
    }
    .card-content {
      height: calc(100% - 250px);
    }
  }
}

.card-with-img.gray {
  figure:before {
    background-color: var(--bg-gray);
  }
}

.card-with-img.light-gray {
  figure:before {
    background-color: var(--bg-light-gray);
  }
}

.card-with-img.white {
  .card-content {
    background-color: var(--white);
  }
  figure:before {
    background-color: var(--white);
  }
}

.card-content {
  background-color: inherit;
  padding: var(--pad);
}

.card-no-img {
  background-color: var(--white);
  display: flex;
  height: 100%;

  .card-content {
    height: 100%;
    padding-top: 4rem;
  }
}

.card-img-lg {
  figure {
    height: 60%;
  }

  .card-content {
    height: 40%;
  }
}

.card.img--front {
  .resource-card h3 {
    margin-bottom: 3rem;
  }

  @include breakpoint(md) {
    margin: 0 1rem;

    .resource-card {
      margin-top: -3rem;
    }
  }
}

.news-module-double {
  display: flex;
  flex-direction: column;
  min-height: 250px;
  position: relative;

  .card-tag.red {
    right: 0;
    justify-content: flex-start;
    width: 100%;
  }

  .svg-wrap {
    background-color: var(--bg-gray);
  }

  figure {
    height: 100%;
    overflow: hidden;
    z-index: 1;
  }

  &.red svg {
    top: 20px;
    width: 100%;
  }

  .card-content {
    background-color: var(--bg-light-gray);
    height: 100%;
  }

  h3, p {
    max-width: 800px;
  }

  &.gray {
    background-color: var(--bg-light-gray);
  }

  @include breakpoint(md) {
    grid-column-start: 1;
    grid-column-end: 3;

    &.full {
      grid-column-end: 3;
    }

    .card-content {
      padding: 3rem 1rem 1rem;
    }

    figure {
      height: calc(100% - 3rem);
      overflow: visible;

      &:before {
        height: 3rem;
        left: 0;
        top: 100%;
        width: 6rem;
      }
    }

    .news__content {
      justify-content: flex-start;
    }

    &.card-with-img {
      display: grid;
      grid-template-columns: calc(50% - 3rem) 1fr;

      .card-content {
        padding: 3rem 3rem 2rem;
      }
    }
  }

  @include breakpoint(lg) {
    &.full {
      grid-column-end: 4;
    }
  }
}

.press .news-module-double {
  margin-bottom: 2rem;

  @include breakpoint(lg) {
    margin-bottom: 0;
  }
}

.article-grid > .news-module-double:first-of-type {
  @include breakpoint(md) {
    min-height: 450px;

    h3 {
      font-size: var(--size-h3);
    }
  }
}

.grid--33 > .news-module-double:first-of-type {
  @include breakpoint(md) {
    min-height: 450px;

    h3 {
      font-size: var(--size-h3);
    }
  }

  @include breakpoint(lg) {
    min-height: 0;

    h3 {
      font-size: var(--size-h4);
    }
  }
}

.search-results-card {
  border-bottom: 1px solid var(--bg-gray);
  margin: 2rem 0;
  min-height: 200px;
  padding: 2rem;
  position: relative;

  &:before {
    bottom: 0;
  }

  &:hover::before {
    height: 0;
  }

  &:hover h3 {
    color: var(--red);
  }

  figure {
    height: 100%;
    position: relative;
    z-index: 1;
  }

  .publish-date {
    display: block;
    margin-bottom: 1rem;
  }

  .card-content {
    height: 100%;
    padding: 2rem 1rem 1rem;
  }

  h3, p {
    max-width: 650px;
    transition: color 0.3s;
  }

  @include breakpoint(md) {
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-gap: 2rem;
    padding: 1rem 2rem 3rem;
    min-height: 400px;

    .tag {
      left: 2rem;
      top: 1rem;
    }

    .card-content {
      padding: 0;
    }
  }
}

.report-card.img--front {
  &:before {
    bottom: 1px;
    left: 1px;
    width: calc(100% - 3rem - 2px);
  }

  &:hover::before {
    height: calc(40% + 1rem - 1px);
  }

  @include breakpoint(md) {
    figure {
      z-index: -1;
    }
  }
}

.news__content {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
}

.job-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;

  p {
    @extend %type--body-copy;
  }

  a {
    color: var(--red);
    margin-top: 1rem;
  }

  @include breakpoint(md) {
    min-height: 350px;
  }
}

span.publish-date + p.publish-date {
  margin-top: 0.25rem;
}


.training-card {
  color: var(--black);
  display: block;
  position: relative;
  text-decoration: none;

  a {
    color: var(--black);
    text-decoration: none;
    width: 100%;
  }

  a.defaultRed {
    color: var(--white);
    display: inline-block;
    width: auto;
  }

  h3 {
    @extend %type--subheader-serif;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  img,a,p,h3,span,svg {
    position: relative;
    z-index: 1;
  }

  &:hover {
    cursor: pointer;

    &:before {
      background-color: var(--med-yellow);
    }

    .inline-link {
      color: var(--med-red);

      .arrow {
        left: 0.5rem;
        &:before { border-left-color: var(--med-red); }
      }
    }
  }
}

.event-module-double {
  display: flex;
  flex-direction: column;
  min-height: 250px;
  position: relative;

  .card-tag.red {
    right: 0;
    justify-content: flex-start;
    width: 100%;
  }

  figure {
    height: 100%;
    overflow: hidden;

  }

  &.card-with-img.img--back figure img { width: 100%; }

  svg {
    top: 20px;
    width: 100%;
  }

  .card-content {
    background-color: var(--bg-light-gray);
    height: 100%;
    padding: 3rem 1rem 1rem;
  }

  h3, p {
    max-width: 800px;
  }

  button {
    margin-top: 2rem;
  }

  @include breakpoint(md) {
    display: grid;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-template-columns: 40% 1fr;

    &.gray {
      background-color: var(--bg-gray);
    }

    figure {
      height: calc(100% - 3rem);
      overflow: visible;
    }

    &.card-with-img.img--back figure:before {
      background-color: var(--white);
      height: 3rem;
      left: 0;
      top: 100%;
      width: 6rem;
    }

    .card-content {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      padding: 2rem 2rem 3rem;
    }
  }

  @include breakpoint(lg) {
    grid-column-end: 4;
  }
}

.bg-light-gray,
.bg-gray {

  .news-module-double.light-gray,
  .news-module-double.gray {
    background-color: var(--white);
    figure:before {
      background-color: var(--bg-gray);
    }
    .card-content {
      background-color: var(--white);
    }
  }
}

.bg-light-gray {
  .news-module-double.gray figure:before {
    background-color: var(--bg-light-gray);
  }
}

.news-module-double.gray {
  figure:before {
    background-color: var(--white);
  }
}

.reorder > .news-module {
  margin-bottom: 2rem;
}
