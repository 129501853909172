$break-xs:  350px;
$break-sm:  580px;
$break-md:  768px;
$break-lg:  1024px;
$break-xl:  1380px;
$break-xxl: 1920px;

$breakpoints: (
  'xs' : ( min-width:  $break-xs ),
  'sm' : ( min-width:  $break-sm ),
  'md' : ( min-width:  $break-md ),
  'lg' : ( min-width:  $break-lg ),
  'xl' : ( min-width:  $break-xl ),
  'xxl': ( min-width:  $break-xxl)
);

@mixin breakpoint($name) {
  @media #{inspect(map-get($breakpoints, $name))} {
    @content;
  }
}
