@import "~scss/breakpoints";
@import "~scss/type";
@import "~scss/colors";

.hero--home {
  h1 {
    @extend %type--header-serif;
    margin: 1rem;
    text-align: center;
    white-space: pre-line;
  }

  .page-header:after {
    display: none;
  }

  .hero-content {
    max-width: $break-lg;
  }

  .buttons .button {
    line-height: 20px;
  }
}

.hero--default {
  .hero-content {
    max-width: 800px;
  }

  .hero-subheading {
    margin: 1rem;
    max-width: 900px;
    // padding: 2rem 0;

    @extend %type--callout;

    p {
      @extend %type--callout;
      text-align: center;
    }

    @include breakpoint(md) {
      margin: 1rem auto;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include breakpoint(lg) {
      margin: 3rem auto;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.hero--home,
.hero--default {
  .hero-content {
    background-color: var(--bg-light-gray);
    margin: 0 -1px;
    padding: var(--pad-lg);
  }

  &.no-img {
    .hero-content {
      background-color: var(--white);
      transform: translateY(0);
      margin-bottom: 0;
    }
  }

  .hero-image {
    position: relative;
    overflow: hidden;
  }

  @include breakpoint(md) {
    .hero-image {
      max-height: 75vh;
    }

    .hero-content {
      margin: 0 auto;
      margin-bottom: -3rem;
      transform: translateY(-3rem);
    }

    .hero-subheading {
      padding-top: var(--pad-lg);
      padding-bottom: var(--pad-lg);
    }

    &.no-img {
      .hero-subheading {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }

  @include breakpoint(lg) {
    .hero-content {
      transform: translateY(-50%);
    }
  }
}

.hero--work {
  position: relative;

  &:after {
    content: '';
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  &.blue:after {
    background-color: var(--med-blue);
  }
  &.green:after {
    background-color: var(--green);
  }
  &.yellow:after {
    background-color: var(--yellow);
  }
  &.red:after {
    background-color: var(--red);
  }

  figure {
    max-height: 75vh;
    overflow: hidden;
  }

  @include breakpoint(md) {
    margin-bottom: 50px;
  }
}

.hero--detail {
  display: flex;
  flex-direction: column-reverse;

  header {
    padding-top: 2rem;
    width: 100%;
  }

  figure {
    margin-bottom: 1rem;
    width: 100%;
  }

  &.black {
    a {
      color: var(--white);
    }

    .page-header:after,
    .page-header.lift h2:after {
      background-color: var(--white);
    }
  }

  h2 {
    margin-top: 2rem;
    padding-top: 1rem;

  }

  @include breakpoint(md) {
    flex-direction: row;

    header {
      margin-right: 2rem;
      padding-right: 2rem;
      padding-top: 0;
      width: 66%;
    }

    figure {
      width: 33%;
    }

    &.reverse {
      flex-direction: row-reverse;

      figure {
        margin-right: 1.5rem;
      }

      header {
        margin-left: 2.5rem;
        padding-left: 1rem;
      }
    }
  }

  @include breakpoint(xl) {
    &.reverse {
      figure {
        margin-right: 2.5rem;
      }

      header {
        margin-left: 3.5rem;
      }
    }
  }
}

.hero--half {
  .black {
    background-color: var(--black);
    color: var(--white);
    padding: 2rem;

    a {
      color: var(--white);
    }

    .page-header {
      padding-bottom: 0;

      h2 {
        margin-top: 2rem;
        padding-top: 2rem;

        &:after {
          background-color: var(--white);
        }
      }

      &:after {
        background-color: var(--white);
      }
    }
  }

  &.default {
    @include breakpoint(md) {
      .black {
        margin: 0 2rem 3rem -2px;
      }
    }
  }

  @include breakpoint(md) {
    display: flex;

    .black,
    figure {
      width: 50%;
    }

    .black {
      padding: 5rem;
    }

    .page-header {
      padding-bottom: 1rem;
    }
  }
}

.breadcrumbs {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style: none;
  margin-top: 1rem;

  a, span {
    @extend %type--button;
    color: var(--black);
    display: block;
    text-decoration: none;
    text-transform: uppercase;
  }

  li {
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;
  }

  li:not(:last-child) {
    position: relative;
    padding-right: 1.5rem;

    &:before {
      border-bottom: 6px solid var(--black);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      bottom: calc(50% - 3px);
      content: '';
      margin: auto;
      position: absolute;
      right: 0.5rem;
      transform: rotate(90deg);
      width: 0;
    }
  }

  &.red {
    color: var(--red);

    span {
      color: var(--red);
    }

    li:before {
      border-bottom-color: var(--red);
    }
  }

  @include breakpoint(md) {
    align-items: center;
    flex-direction: row;

    li {
      margin-bottom: 0;
    }
  }
}

.hero {
  &.bg-blue {
    background-color: var(--med-blue);
  }
  &.bg-green {
    background-color: var(--green);
  }
  &.hero--lighten.bg-green {
    background-color: var(--bg-green);
  }
  &.bg-yellow {
    background-color: var(--yellow);
  }
  &.hero--lighten.bg-yellow {
    background-color: var(--med-yellow);
  }
  &.bg-red {
    background-color: var(--red);
  }
  &.bg-black {
    background-color: var(--black);
    color: var(--white);

    .breadcrumbs {
      a, span {
        color: var(--bg-blue);
      }

      li:before {
        border-bottom: 6px solid var(--bg-blue);
      }
    }
  }

  figure {
    max-height: 80vh;
  }

  video {
    height: auto;
    width: 100%;
  }
}

.head-blue {
  // max-height: 1000px;
  // overflow: hidden;

  .hero-content {
    background-color: var(--bg-blue);
  }

  .hero-subheading {
    text-align: center;

    p {
      @extend %type--subheader-sans;
    }
  }

  @include breakpoint(md) {
    .hero-content {
      margin-bottom: -3rem;
    }
  }

  @include breakpoint(lg) {
    .hero-content {
      margin-bottom: -15%;
    }
  }
}
