@import "~scss/breakpoints";
@import "~scss/colors";
@import "~scss/type";

.text-block-link {
  padding: 1rem;

  h3 {
    font-size: var(--size-h3);
    font-family: var(--serif);
    margin-bottom: 1rem;
    min-height: 80px;
    line-height: 1.2;
  }

  p {
    @extend %type--body-copy;
  }

  &.inline {
    h3 { display: block; }
    p {
      display: inline;
      padding-right: 10px;
    }
  }

  &.hide-copy-mobile {
    p {
      display: none;
    }
  }

  &.bg-yellow {
    background-color: var(--bg-yellow);
  }
  &.bg-green {
    background-color: var(--bg-green);
  }
  &.bg-blue {
    background-color: var(--bg-blue);
  }

  @include breakpoint(md) {
    h3 {
      min-height: 50px;
    }

    &.hide-copy-mobile {
      p {
        display: inline;
      }
    }
  }

  @include breakpoint(lg) {
    padding: 2rem;
  }
}

.inline-link {
  @extend %color--red;
  @extend %type--body-copy;
  display: inline-block;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: var(--med-red);

    .arrow {
      left: 0.5rem;
      &:before {
        border-left-color: var(--med-red);
      }
    }

    &.back-link .arrow {
      left: -0.5rem;
    }
  }
}

.comma-separate {
  padding-right: 5px;
  position: relative;

  &:after {
    content: ", ";
  }
}

span.arrow {
  display: inline-block;
  height: 10px;
  left: 0;
  margin-left: 0.5rem;
  position: relative;
  transition: all 0.3s;

  &:before {
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    border-left: 8px solid var(--red);
    bottom: 0;
    content: '';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    transition: border 0.3s;
    width: 0;
  }
}


.svg-link {
  display: inline-block;
  margin-right: 25px;
  max-width: 25px;

  svg {
    width: 100%;

    * {
      fill: var(--black);
      transition: all 0.3s;
    }
  }

  &:hover {
    svg * { fill: var(--red); }
  }
}
