@import "~scss/type.scss";
@import "~scss/breakpoints.scss";

.nav {
  --nav-bg: #FBFBFBFB;

  background-color: var(--white);
  display: flex;
  flex-direction: column-reverse;
  height: var(--navHeight);
  justify-content: space-between;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  .button.whiteBorder {
    background-color: var(--white);
    border: 1px solid var(--black);
    color: var(--black);
    a { color: var(--black); }
  }

  @include breakpoint(lg) {
    transition: height 0.3s 0s;
  }
}

.nav-button {
  white-space: nowrap;
  z-index: 1;

  a {font-size: 14px;}

  @include breakpoint(lg) {
    .defaultRed {
      margin-left: 30px;
    }
  }

  @include breakpoint(xl) {
    margin: 0 0 0 15px;
  }

  @include breakpoint(xxl) {
    a {font-size: 16px;}
  }
}

.nav-item {
  @extend %type--button;
  font-size: 14px;
  color: var(--black);
  display: block;
  padding: 0 1rem;
  text-decoration: none;
  transition: color 0.3s 0s;

  &:hover {
    color: var(--red);
    cursor: pointer;
  }

  &.active {
    color: var(--red);
  }

  &:focus-visible {
    outline: 1px solid var(--black);
  }

  @include breakpoint(lg) {
    display: inline-block;
    white-space: nowrap;

    &.top-nav {
      padding: 0 0 0 1.5rem;
    }
  }

  @include breakpoint(xl) {
    &.top-nav {
      padding: 0 0 0 50px;
    }
  }


  @include breakpoint(xxl) {
    font-size: 16px;
  }
}

.site-logo {
  display: block;
  width: 50%;

  svg {
    max-height: var(--navHeight);
    max-width: 170px;
  }

  @include breakpoint(lg) {
    width: 15%;

    svg {
      min-width: 100px
    }
  }

  @include breakpoint(xxl) {
    width: 25%;

    svg {
      max-width: 270px;
    }
  }
}

.nav-subnav {
  background-color: #FBFBFBFB;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding-left: 1rem;

  ul {
    padding-left: 1rem;
  }

  .subnav-link {
    @extend %type--cta-serif;
    color: var(--black);
    display: block;
    text-decoration: none;

    &:hover {
      color: var(--red);
      .subnav-item-parent:after {
        background-color: var(--red);
        width: 100%;
      }
    }
  }

  ul {
    list-style: none;
  }

  .nav-item {
    display: block;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem;
  }

  .nav-item-parent {
    font-weight: 600;
    margin: 1rem 0;
  }

  @include breakpoint(lg) {
    border-bottom: 1px solid var(--bg-gray);
    flex-direction: row;
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    width: 100%;

    .nav-item {
      margin-bottom: 0rem;
      white-space: normal;
    }
  }
}

.subnav-item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .col {
    display: block;
    width: 66%;
  }

  @include breakpoint(lg) {
    align-items: center;
    border-right: 1px solid var(--bg-gray);
    flex-direction: row;
    padding: 50px 25px;
    // width: 33%;
  }

  @include breakpoint(xl) {
    padding: 50px;
  }
}

.subnav-item-wrap {
  // align-items: flex-start;
  // display: flex;
  // justify-content: space-around;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  width: 100%;

  .flex-row {
    // align-items: flex-start;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // width: 33%;
  }

  li {
    list-style: none;
    // line-height: 1.5;
    padding-bottom: 1rem;
  }

  .nav-item {
    position: relative;

    &:before {
      border-bottom: 6px solid var(--black);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      content: '';
      left: 0;
      margin: auto;
      position: absolute;
      top: 4px;
      transform: rotate(90deg);
      transition: all 0.3s;
      width: 0;
    }

    &:hover:before,
    &.active:before {
      border-bottom-color: var(--red);
    }
  }

  @include breakpoint(lg) {
    border-right: 1px solid var(--bg-gray);
    flex-direction: row;
    padding: 30px;
    width: 50%;

    .flex-row & {
      padding: 50px 100px;
    }
  }

  @include breakpoint(xl) {
    padding: 50px;
  }
}

.subnav-item-parent {
  display: inline-block;
  margin-bottom: 2rem;
  position: relative;
  transition: color 0.2s 0s;

  &:after {
    background-color: var(--black);
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: calc(100% + 0.5rem);
    transition: all 0.3s;
    width: 20px;
  }
}

.subnav-item-description {
  @extend %type--body-copy;
  color: var(--gray);
}

.nav-subnav__label {
  outline: none;

  > span {
    display: block;
    position: relative;
    margin-right: 10px;

    &:before {
      border-bottom: 6px solid var(--black);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      bottom: 5px;
      content: '';
      left: calc(100% + 4px);
      margin: auto;
      position: absolute;
      transform: rotate(180deg);
      transition: border-bottom-color 0.3s, transform 0.3s;
      width: 0;
    }
  }

  &:hover > span:before {
    border-bottom-color: var(--red);
  }

  &.active > span:before {
    border-bottom-color: var(--red);
  }

  &.open > span:before {
    border-bottom-color: var(--red);
    transform: rotate(0);
  }
}

.nav__links {
  align-items: flex-end;
  border-bottom: 1px solid var(--bg-gray);
  border-top: 1px solid var(--bg-gray);
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include breakpoint(lg) {
    align-items: center;
    min-height: var(--navHeightSm);
  }

  @include breakpoint(xxl) {
    padding-left: var(--margin);
    padding-right: var(--margin);
  }
}

.nav__site-links {
  background-color: white;
  bottom: 0;
  height: 100vh;
  left: 0;
  list-style: none;
  overflow: auto;
  position: fixed;
  right: 0;
  top: var(--navHeight);

  > li {
    border-bottom: 1px solid var(--bg-gray);
    padding: 1rem;
  }

  ul {
    padding-top: 1rem;
  }

  @include breakpoint(lg) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding-right: 0.5rem;
    position: static;

    ul {
      padding: 0;
    }

    > li {
      border-bottom: none;
      padding: 0;
    }
  }
}

.nav__langs {
  align-items: center;
  background-color: #FBFBFBFB;
  display: flex;
  justify-content: center;
  list-style: none;
  overflow: hidden;
  width: 100%;

  > li {
    padding: 0 0.5rem;
  }

  @include breakpoint(lg) {
    justify-content: flex-end;
    padding: 0 0.5rem;

    > li { padding: 0.5rem; }
  }

  @include breakpoint(xxl) {
    padding-right: var(--margin);
  }
}

.nav-lang {
  @extend %type--button;
  color: var(--gray);
  margin: 0 0.5rem;
  text-decoration: none;
  transition: opacity 0.3s 0s;

  &.active {
    color: var(--black);
  }
}

.menu-toggle {
  appearance: none;
  outline: none;
  background-color: transparent;
  border: none;
  border-top: 2px solid var(--black);
  border-bottom: 2px solid var(--black);
  bottom: 30px;
  display: block;
  height: 25px;
  position: absolute;
  right: 1.5rem;
  transition: all 0.3s;
  width: 30px;

  &:before,
  &:after {
    background-color: var(--black);
    bottom: 0;
    content: '';
    height: 30px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(90deg);
    transition: all 0.3s;
    width: 2px;
  }

  &.is-open {
    border: none;

    &:before {
      width: 2px;
      transform: rotate(45deg);
    }

    &:after {
      width: 2px;
      transform: rotate(-45deg);
    }
  }
}

.nav-search {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  width: 100%;

  .close {
    height: 5px;
    display: block;
    position: relative;

    &:before,
    &:after {
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    &:before {
      border-bottom: 2px solid var(--black);
      height: 0;
      transform: rotate(-45deg);
    }

    &:after {
      border-bottom: 2px solid var(--black);
      height: 50%;
      transform: rotate(45deg);
    }
  }

  @include breakpoint(lg) {
    align-items: center;
    background-color: #FBFBFB;
    justify-content: center;
    min-height: 200px;
    left: 0;
    right: 0;
    position: absolute;
    transition: transform 0.3s;
    top: 100%;

    .close-button {
      padding: 0;
      position: absolute;
      right: 1rem;
      top: 1rem;
      height: 45px;

      &:hover {
        background-color: transparent;
        transform: rotate(180deg);
      }
    }
  }
}

#search-form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: var(--max);
  padding: 1rem 0 0 1rem;
  position: relative;
  width: 100%;

  @extend %type--cta-serif;
  font-family: var(--sans);

  &.active {
    label { display: none; }
  }

  .icon {
    margin-right: 12px;
    max-height: 33px;
    padding: 0 3px;
  }

  label {
    color: var(--gray);
    pointer-events: none;
    position: absolute;
    top: 1.5rem;
  }

  input[type="text"] {
    background-color: var(--white);
    border: 0;
    border-bottom: 1px solid var(--black);
    color: var(--black);
    margin-right: 10px;
    padding-bottom: 0.5rem;
    outline: none;
    width: 100%;

    @extend %type--cta-serif;
  }

  button {
    margin-top: 1rem;
    &:hover {
      background-color: transparent;
      svg  {
        path {fill: var(--red);}
        circle {stroke: var(--red);}

        * {
          transition: all 0.3s;
        }
      }
    }
  }

  @include breakpoint(lg) {
    align-items: flex-end;
    flex-direction: row;
    font-family: var(--serif);
    padding: 1.5rem 3rem;
    width: 75%;

    input[type="text"] {
      background-color: #FBFBFB;
    }

    label {
      position: absolute;
      left: 3rem;
      top: 2.25rem;
    }

    button {
      margin-top: 0;
      padding: 0.75rem 0 0;
    }
  }
}

.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
}

.skip-to-main-content-link {
  @extend %type--body-copy;
  background-color: var(--black);
  border: none;
  color: var(--white);
  left: -9999px;
  opacity: 0;
  padding: 1em;
  position: absolute;
  text-decoration: none;
  z-index: 999;
}
.skip-to-main-content-link:focus {
  left: 50%;
  opacity: 1;
  transform: translateX(-50%);
}